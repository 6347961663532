<template>

  <div>

    <InlineNotification Heading="Artist Cancellations" class="ml-4 bg_guitarist">
      <template slot="body">
        <p>
          If an artist has cancelled a booking, the gig will appear in this section.
        </p>
        <p>If and when another artist is booked onto the gig or if the gig is cancelled, the gig will be removed from this section and will then be listed in the corresponding page.</p>
      </template>
    </InlineNotification>

    <PageDetails>

      <div class="lg:max-w-6xl">

        <div class="space-y-8 mb-16">

          <div class="ml-4 mt-12 flex-row lg:flex lg:space-x-24 space-y-6 lg:space-y-0">
            <div>
              <div class="flex space-x-2 items-center">
                <OfficeBuildingIcon class="text-gp_pink-default-500"/>
                <span class="font-black">Venues</span>
              </div>
              <div class="w-80 mt-4">
                <MultiselectFilter
                    :options="compVenues"
                    :selectedOptionProps="selectedVenues"
                    :searchable="true"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="false"
                    :show-labels="false"
                    :displayText="venueDisplayText"
                    @optionSelected="venueSelected"
                    @optionDeselected="venueDeselected"
                    :placeHolder="'Filter by venue'"
                    :track-by="'id'"
                ></MultiselectFilter>
              </div>
            </div>
          </div>

          <div class="xl:flex xl:space-x-32 pb-4 pt-2 ml-4">
            <DateFilter
                :dateStartProp="dateFilterStart"
                :dateEndProp="dateFilterEnd"
                @dateFilterChange="dateFilterChange"
            />
          </div>

          <div v-show="isGenre" class="ml-4 my-12 flex-row lg:flex lg:space-x-24 space-y-6 lg:space-y-0">
            <div>
              <div class="flex space-x-2 items-center">
                <TagIcon class="text-gp_pink-default-500"/>
                <span class="font-black">Gig Tags</span>
              </div>
              <div class="w-80 mt-4">
                <TRichSelect
                    multiple
                    v-model="setTagFilters"
                    ref="iconselect"
                    class="col-span-12 lg:col-span-5 cursor-pointer"
                    :close-on-select="false" valueAttribute="id"  textAttribute="name"
                    :options="venue_custom_tags"
                    placeholder="Filter by custom tag"
                >
                  <template slot="option" slot-scope="{ index, isHighlighted, isSelected, className, option }">
                    <div class="flex gap-4 py-2">
                      <component :is="icons[option.raw.icon]" class="ml-3"/>  {{ option.raw.name }}
                    </div>
                  </template>
                </TRichSelect>
              </div>
            </div>
          </div>

          <div v-show="isGenre" class="flex flex-row my-4 ml-4">
            <t-toggle name="show_signed_off_gigs" v-model="toggleSignedOff" class="mr-4"/>
            <label>Show signed-off gigs</label>
          </div>

          <button v-show="!isLoading && hasData"
                  @click="exportCSV()"
                  :loading="loadingExports"
                  :disabled="loadingExports"
                  class="cta icon bg-black px-8 ml-4 flex items-center"><DocumentTextIcon class="w-5 h-5 mr-2" />Export to CSV ({{ excludeCSV }})</button>
        </div>


        <template v-if="isLoading">
          <div class="p-8 flex justify-center items-start">
            <!-- no need for loading state as using NProgress -->
          </div>
        </template>

        <template v-else-if="!isLoading && !hasData">
          <NoDataMsgWrapper>
            There are currently no cancellation requests
          </NoDataMsgWrapper>
        </template>

        <template v-else>
          <div class="py-2 bg-[#0D1C43] rounded-lg max-w-5xl flex items-center space-x-44 uppercase text-gray-200 text-sm font-medium">
            <input ref="selectall" type="checkbox" @change="selectAll" class="ml-6 bg-gray-100" />
            <div class="w-52 pl-14 flex space-x-3 invisible xl:visible">
              <div class="bg-gp_pink-default-400 w-[2px] h-5 rounded-lg"/>
              <span>Gig Details</span>
            </div>
            <div class="hidden xl:flex space-x-3 pl-16">
              <div class="bg-gp_pink-default-400 w-[2px] h-5 rounded-lg"/>
              <span>Artist</span>
            </div>
          </div>

          <div class="flex flex-col lg:flex-row py-8 max-w-5xl border my-3 rounded-xl shadow-lg bg-white" v-for="(bid, index) in bids" :key="'bid.id' + index">
            <div class="flex">
              <div class="flex flex-row justify-center items-center ml-6 min-w-[150px]">

                <div class="space-y-8 md:space-y-4 ml-6">
                  <div class="flex space-x-2 items-center">
                    <EyeIcon @click="$router.push('/gigs/' + bid.gig.id)" class="w-5 h-5 text-gp_pink-default-500 cursor-pointer"/>
                    <router-link :to="'/gigs/' + bid.gig.id"  class="text-gp_pink-default-500 text-md font-extralight hidden md:block">View gig</router-link>
                  </div>
                  <a v-if="status(bid).showRelist" class="mt-1 block text-gp_pink-default-500 text-md font-extralight" @click="relistBooking(bid)">Re-list/Cancel gig</a>
                </div>
              </div>

              <div class="w-96 flex space-x-5 ml-6 md:ml-12 pr-2 md:pr-0">
                <div>
                  <StatusBadge :status="status(bid)" class="w-32 lg:w-44 ml-2" />
                  <div class="mt-4 text-md space-x-3">
                    <div class="bg-[rgb(209, 250, 229)] w-[2px] h-full rounded-lg"/>

                    <span class="font-bold">{{bid.gig.venue.name}}</span>
                    <span class="block">{{bid.gig.name}}</span>
                    <span class="block">{{  fullDate(bid.gig.start) }}</span>
                    <div class="flex items-center space-x-2 mt-2">
                      <ClockIcon class="w-4 h-4"/>
                      <span class="block text-sm">{{timeAgo(bid.gig.start)}} ago</span>
                    </div>

                    <div class="block xl:hidden items-center mt-6">
                      <div class="flex items-center space-x-4">
                        <hr class="w-4 h-[2px] bg-[rgb(209, 250, 229)] border-0 rounded">
                        <strong class="block text-sm">Artist cancelled</strong>
                      </div>
                      <div class="flex space-x-4 mt-6">
                        <div class="flex flex-col items-center">
                          <img class="rounded-full h-12 w-12 object-cover border-2 border-[bg-[rgb(209, 250, 229)]]" :src="getArtistsImage(bid)" />
                        </div>
                        <div class="">
                          <span class="block font-bold">{{ getArtistsName(bid) }}</span>
                          <div class="flex items-center space-x-2">
                            <span class="block text-md">Fee: £{{bid.fee}}</span>
                          </div>
                          <div class="flex items-center space-x-2">
                            <span class="block"><strong>Cancellation reason:</strong> {{  bid.cancellation_reason_other !== null ? bid.cancellation_reason_other : bid.cancellation_reason.reason }}</span>
                          </div>
                          <span v-if="bid.display_amends"><strong>Confirmed - Amended</strong></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="ml-3 hidden xl:block">
              <div class="inline-flex items-center justify-center w-full">
                <hr class="w-64 h-[2px] bg-gp_pink-default-400 border-0 rounded">
                <div class="absolute px-3 -translate-x-1/2 bg-white uppercase font-medium text-xs text-gray-600">
                  <span class="uppercase font-medium text-xs text-gray-600">Cancelled artist</span>
                </div>
              </div>

              <div class="flex space-x-4 mt-6">
                <div class="flex flex-col items-center">
                  <img class="rounded-full h-12 w-12 object-cover border-2 border-gp_pink-default-400" :src="getArtistsImage(bid)" />
                </div>
                <div class="">
                  <span class="block font-bold">{{ getArtistsName(bid) }}</span>
                  <div class="flex flex-col">
                    <span class="block text-md">Fee: £{{bid.fee}}</span>
                    <span class="block text-md">
                      <span class="block"><strong>Cancellation reason:</strong> {{  bid.cancellation_reason_other !== null ? bid.cancellation_reason_other : bid.cancellation_reason.reason }}</span>
                    </span>
                  </div>
                  <span v-if="bid.display_amends"><strong>Confirmed - Amended</strong></span>
                </div>
              </div>
            </div>
          </div>

          <div class="max-w-5xl shadow overflow-hidden border-b">
            <Pagination
                @changePage="(n) => paginate_currPage = n"
                :paginate_from="paginate_from"
                :paginate_to="paginate_to"
                :paginate_total="paginate_total"
                :paginate_currPage="paginate_currPage"
                :paginate_lastPage="paginate_lastPage"
            ></Pagination>
          </div>
        </template>

        <div v-show="selectedBids.length > 0" class="action-bar justify-end">
          <button @click="openModal"
                  :loading="isSigningOff"
                  :disabled="signOffButtonDisabled"
                  class="cta icon bg-indigo-600">{{ this.signOffButtonLabel }}</button>
        </div>

      </div>
      <AmendCustomGigTag ref="updateGigTags" v-on:updatedTag="updatedTags($event)"/>


      <template v-if="selectedBooking">
        <CancelBooking
            identity="cancelBookingModal"
            :is-open="cancelBookingModalIsOpen"
            :gig-id="selectedBooking.gig.id"
            :gig-name="selectedBooking.gig.name"
            :gig-start="selectedBooking.gig.start"
            :booking="selectedBooking"
            :gig="selectedBooking.gig"
            :artist-cancelled-booking="true"
            @closed="cancelBookingModalIsOpen = false"
            @deleted="onBookingDeleted"
        ></CancelBooking>
      </template>

    </PageDetails>
  </div>

</template>

<script type="text/javascript">
import PageDetails from '@/components/ui/wrappers/PageDetails.vue'
import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
import fullDate from  '@/utils/format-date-full.js'
import timeAgo from  '@/utils/format-date-timeago.js'
import InlineNotification from '../../components/notifications/inline.vue';
import SignoffArtists from '../../components/modal/SignoffArtists.vue';
import NProgress from 'nprogress';
import AmendBidFee from '../../components/modal/gigs/AmendBidFee.vue'
import StatusBadge from '../../components/iconsets/StatusBadge.vue'
import Pagination from "@/components/elements/Pagination.vue";
import {pastGigsMethods} from "@/state/helpers";
import DateFilter from '../../components/filters/DateFilter.vue';
import { DateTime } from 'luxon';
import { EyeIcon, DocumentTextIcon, PencilAltIcon, ClockIcon, TagIcon, OfficeBuildingIcon } from "@vue-hero-icons/outline"
import AmendCustomGigTag from '../../components/modal/gigs/AmendCustomTags.vue';
import  * as icons from "@vue-hero-icons/outline"
import {mapState} from "vuex";
import { TRichSelect } from 'vue-tailwind/dist/components';
import client from '@/utils/client.js'
import {TToggle} from 'vue-tailwind/dist/components';
import { apiMethods } from '@/state/helpers.js'
import MultiselectFilter from '@/components/filters/MultiSelectFilter.vue'
import CancelBooking from "@/components/modal/gigs/CancelBooking.vue";

export default {
  name: "ArtistCancellations",
  components:{
    CancelBooking,
    Pagination,
    PageDetails,
    NoDataMsgWrapper,
    InlineNotification,
    SignoffArtists,
    AmendBidFee,
    StatusBadge,
    DateFilter,
    EyeIcon, DocumentTextIcon, PencilAltIcon, ClockIcon, TagIcon, OfficeBuildingIcon,
    AmendCustomGigTag,
    TRichSelect,
    TToggle,
    MultiselectFilter
  },
  data() {
    return {
      venue_custom_tags: [],
      setTagFilters: [],
      icons: icons,
      selectedIndex: null,
      paginate_from: null,
      paginate_to: null,
      paginate_total: null,
      paginate_currPage: 1,
      paginate_lastPage: null,
      selectedBids: [],
      mobile: false,
      showAmendBidFeeModal: false,
      selectedBid:null,
      rows: [],
      compVenues: [],
      selectedVenues: [{id: 'all', name: 'All venues'}],
      isLoading:false,
      dateFilterStart: this.getCalendarStartDate(),
      dateFilterEnd: this.getCalendarEndDate(),
      toggleSignedOff: false,
      loadingExports: false,
      cancelBookingModalIsOpen: false,
      selectedBooking:null,
      showRelist: true
    }
  },
  watch: {
    paginate_currPage() {
      this.load();
    },
    dateFilterStart() {
      localStorage.setItem('cancellationRequestedGigsStartDate', this.dateFilterStart)
    },
    dateFilterEnd() {
      localStorage.setItem('cancellationRequestedGigsEndDate', this.dateFilterEnd)
    },
    setTagFilters(){
      this.load();
    },
    toggleSignedOff() {
      this.load();
    },
    selectedVenues(){
      if (this.selectedVenues.length === 0) {
        this.selectedVenues = [{id: 'all', name: 'All'}];
      }
      this.paginate_currPage = 1;
      this.load();
    },
  },
  mounted(){
    let query = window.matchMedia("(max-width:768px)");

    if (query.matches){
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    client.get('custom-tags').then(response => (this.venue_custom_tags = response.data.data))

    client.get('venues-filter-list').then((response) => { this.compVenues = [
      { name:"All venues", id:"all" },
      ...response.data.data
    ];})

    this.load()
  },
  computed: {
    ...mapState({
      userRoles: (state) => state.user.rolesPermissionsSlugs,
    }),
    venueParams() {
      if (!this.compVenues.length || (this.compVenues[0].id === 'all' && this.compVenues.length === 1))
        return [];

      if (!this.selectedVenues.length || (this.selectedVenues[0].id === 'all' && this.selectedVenues.length === 1))
        return [];

      const venues = this.selectedVenues[0].id === 'all' ? this.compVenues.slice(1) : this.selectedVenues;
      return venues.map((venue) => venue.id);
    },
    excludeCSV(){
      return this.selectedBids.length === 0 ? 'All' : this.selectedBids.length;
    },
    hasTagAccess()
    {
      if (this.userRoles && this.userRoles.length > 0) {
        return this.userRoles[0] == 'full-access';
      } else {
        return null;
      }
    },
    userData(){
      return this.$store.getters['user/userData']
    },
    isGenre() {
      return this.userData && this.userData.email.includes('genremusic')
    },
    venueDisplayText() {
      return this.selectedVenues[this.selectedVenues.length-1].name
    },
    numberSelected(){
      return this.selectedBids.length
    },
    getArtistsName(){
      return (gig) => gig && gig.artist ? gig.artist.name : "no artists details"
    },
    getArtistsImage(){
      return (gig) => gig && gig.artist.image && gig.artist.image.url ? gig.artist.image.url : "no image"
    },
    hasData(){
      if(this.rows){
        if(this.rows.length){
          return true;
        }
      }
      return false;
    },
    signOffButtonDisabled(){
      return !this.numberSelected > 0
    },
    signOffButtonLabel(){
      return `Sign off selected performances (${ this.numberSelected })`
    },
    isSigningOff(){
      return false;
    },
    bids(){
      if(this.rows){
        return this.rows
      }else{
        return []
      }
    },
    checkDevice(){
      return this.mobile
    }
  },
  methods: {
    ...apiMethods,
    ...pastGigsMethods,
    fullDate,
    timeAgo,
    status(bid) {
      let gigStat = {
        code: 700,
        name:'Artist needs replacing',
        type:'CANCELLATION_REQUESTED_BY_ARTIST',
        bg:'rgb(251,121,0)',
        color:'rgb(255,255,255)',
        class:'cancelled-by-artist',
        showRelist: true
      }

      if(bid.gig_relisted === true && bid.status === 'CANCELLATION_REQUESTED_BY_ARTIST') {
        gigStat.name = 'Relisted'
        gigStat = {
          code: 50,
          name:'Relisted',
          type:'CANCELLED',
          bg:'rgb(243, 244, 246)',
          color:'rgb(31, 41, 55)',
          class:'gig-cancelled',
          showRelist: false
        }
      }else if (bid.gig.status === 'CANCELLED') {
        gigStat = {
          code: 50,
          name: 'Gig cancelled',
          type: 'CANCELLED',
          bg: 'rgb(243, 244, 246)',
          color: 'rgb(31, 41, 55)',
          class: 'gig-cancelled',
          showRelist: false
        }
      } else if(bid.gig.status !== 'CANCELLATION_REQUESTED_BY_ARTIST') {
        gigStat.name = 'Artist cancelled'
        gigStat.showRelist = false
      }

      return gigStat
    },
    relistBooking($bid){
      this.selectedBooking = null;

      setTimeout(() => {
        this.selectedBooking = $bid;
        this.cancelBookingModalIsOpen = true;
      }, 50)

    },
    onBookingDeleted(){
      this.cancelBookingModalIsOpen = false;
      this.selectedBooking = null;
      location.reload();
    },
    venueSelected(option) {
      if (option.id === 'all') {
        this.selectedVenues = [{id: 'all', name: 'All venues'}];
      } else {
        const index = this.selectedVenues.findIndex((element) => element.id === 'all');
        if (index !== -1) {
          this.selectedVenues.splice(index, 1);
        }
        this.selectedVenues.push(option);
      }
    },
    venueDeselected(option) {
      if (option.id !== 'all') {
        const index = this.selectedVenues.findIndex((element) => element.id === option.id);
        if (index !== -1) {
          this.selectedVenues.splice(index, 1);
        }
      }
    },
    exportCSV(){
      NProgress.start();
      this.loadingExports = true;

      if (this.selectedBids.length) {
        this.bookingExport(this.selectedBids.map(m => m))
            .then(() => {
              NProgress.done();
              this.loadingExports = false;
            });
      } else {
        this.getArtistCancellationsWithTrashed({page: this.paginate_currPage, paginate: false, dateFilterStart: this.dateFilterStart, toggleSignedOff: this.toggleSignedOff, custom_tags: this.setTagFilters, dateFilterEnd: this.dateFilterEnd})
            .then((response) => {
              this.bookingExport(response.map(f => f))
                  .then(() => {
                    NProgress.done();
                    this.loadingExports = false;
                  });
            })
      }
    },
    firstCustomTagName(val){
      return val.name
    },
    firstCustomTagIconName(val){
      return val.icon
    },
    firstCustomTagIconColour(val){
      return val.icon_colour
    },
    openUpdateTagsModal(index, gig) {
      this.selectedIndex = index;
      this.$refs.updateGigTags.updateGigTag(gig);
    },
    updatedTags(event) {
      this.rows[this.selectedIndex].gig.custom_tags = event;
    },
    load() {
      this.isLoading = true;
      NProgress.start();
      this.getArtistCancellationsWithTrashed({
        page: this.paginate_currPage,
        paginate: true,
        dateFilterStart: this.dateFilterStart,
        toggleSignedOff: this.toggleSignedOff,
        custom_tags: this.setTagFilters,
        dateFilterEnd: this.dateFilterEnd,
        venue_ids: this.venueParams,
      })
          .then((response) => {
            this.rows = response.data;
            this.paginate_from = response.meta.from
            this.paginate_to = response.meta.to
            this.paginate_total = response.meta.total
            this.paginate_lastPage = response.meta.last_page

            NProgress.done();
            this.isLoading = false;
          })
          .catch(err => {
            console.log(err);
            NProgress.done();
            this.isLoading = false;
          })
    },
    selectAll($event){
      const checked = $event.target.checked;
      this.$refs.check.forEach((c) => {
        c.checked = checked;
        this.toggleOffer(c.checked, c.id);
      });
    },
    cancelModal(){
      this.showAmendBidFeeModal = false;
    },
    editBidFee($bid){
      this.selectedBid = $bid;
      this.showAmendBidFeeModal = true;
    },
    toggleOffer: function(isOn, $bidid){
      if (isOn === true)
      {
        this.selectedBids.push($bidid.toString());
      }
      else
      {
        this.selectedBids.map((f, index) => {
          if (f.toString() === $bidid.toString()) {
            this.selectedBids.splice(index, 1);
          }
        })
      }
    },
    openModal(){
      this.$refs.signoffModal.open();
    },
    dateFilterChange(dateObject) {
      this.dateFilterStart = dateObject.dateFilterStart;
      this.dateFilterEnd = dateObject.dateFilterEnd;
      this.load();
    },
    getCalendarStartDate() {
      const cancellationRequestedGigsStartDate = localStorage.getItem('cancellationRequestedGigsStartDate')
      if (cancellationRequestedGigsStartDate) {
        return cancellationRequestedGigsStartDate
      } else {
        return DateTime.now().minus({months: 1}).toISODate()
      }
    },
    getCalendarEndDate() {
      const cancellationRequestedGigsEndDate = localStorage.getItem('cancellationRequestedGigsEndDate')
      if (cancellationRequestedGigsEndDate) {
        return cancellationRequestedGigsEndDate
      } else {
        return DateTime.now().toISODate()
      }
    }
  }
};

</script>