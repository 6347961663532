<template>
    <div>

        <ArtistRemove ref="artistremove" @refresh="updateData()"/>

        <Header Heading="Add your GigPig profile here" class="ml-8" />
        <InlineNotification Heading="Your artist profile" class="bg_guitarist ml-4">
            <template slot="body">

                <DictionaryContent :hookId="14" dynamicUrl="artist/profiles">
                    <p>A band, a DJ, a solo act, a duo, or all of the above? Create separate profiles to show off each act, so venues can spot you when they’re booking gigs.</p>                 
                </DictionaryContent>

            </template>
            <template slot="cta">
                <router-link to="/artists/add" class="gp_cta">Add new artist profile</router-link>
            </template>
        </InlineNotification>

        <div class="max-w-7xl m-6"> 
            <div class="flex flex-col ">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8" v-if="loaded">
                        <div v-if="rows.length > 0" class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200 text-left">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th
                                        scope="col"
                                        class="px-9 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                        Artist
                                        </th>
                                        <th
                                        scope="col"
                                        class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                        </th>
                                        <th
                                        scope="col"
                                        class="px-5 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                        Type
                                        </th>
                                        <th
                                        scope="col"
                                        class="px-5 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                        NAME
                                        </th>
                                        <th scope="col" class="relative px-6 py-3">
                                        <span class="sr-only">Edit</span>
                                        </th>
                                        <th
                                        scope="col"
                                        class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(row, index) in rows" :key="index" :row="row">
                                        <td>
                                            <router-link :to="'/artists/' + row.id"> 
                                            <img :src="row.icon" :alt="row.name + '  logo'" class="w-28 h-28 object-cover object-center" /></router-link>
                                        </td>
                            <td   td class="px-4 lg:w-84 text-gp_pink-default-500  justify-center items-center ">
                            <div class="grid grid-flow-col gap-4">
                                <router-link title="View profile"  class="h-full flex" :to="'/artists/profiles/' + row.id">
                                    <EyeIcon/> <span class="hidden lg:inline-block ml-4">View</span>
                                </router-link> 
                                <router-link title="Edit profile" class="h-full  flex" :to="'/artists/' + row.id">
                                    <PencilIcon /> <span class="hidden lg:inline-block ml-4">Edit</span>
                                </router-link>
                                <div title="Remove profile" class="h-full flex cursor-pointer" @click="openModal(row.id)">
                                    <TrashIcon /> <span class="hidden lg:inline-block ml-4">Remove</span>
                                </div>
                                </div>
                            </td>
                            <td class="px-6 text-left">
                                {{row.tier}}</td>
                            <td class="px-6 text-left">
                                <router-link :to="'/artists/' + row.id"> {{row.name}}</router-link>
                                <StarRating class="mt-2" :rating="row.rating"/>
                            </td>
                        </tr> 
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <NoDataMsgWrapper>
                        You have not set up any profiles yet.
                    </NoDataMsgWrapper>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div> 
</template>

<script type="text/javascript">

import { apiComputed, apiMethods } from '@/state/helpers.js'  
import InlineNotification from '../../components/notifications/inline.vue'
import Header from '../../components/listing/header.vue'
import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
import ArtistRemove from '../../components/modal/ArtistRemove.vue';
import { EyeIcon, PencilIcon, TrashIcon } from '@vue-hero-icons/outline'
import DictionaryContent from '../../components/dictionary/DictionaryContent.vue';
import StarRating from '../../components/rating/StarRating.vue';

export default
{
  name: "ArtistsProfiles",
  components: {
    InlineNotification, Header, EyeIcon, PencilIcon, TrashIcon, NoDataMsgWrapper, ArtistRemove,
    DictionaryContent, StarRating
},
  computed: {
    ...apiComputed,
  },
    data(){
        return {
            rows: [],
            loaded: false
        }
    },
    mounted(){
        this.updateData();
    },
    methods: {
        ...apiMethods, 
        openModal($id){
            let $selected = this.rows[this.rows.findIndex(row => row.id === $id)];
            this.$refs.artistremove.show($selected, true);
        },
        updateData(){   
            this.$store
                .dispatch('api/getArtists')
                .then(() => {
                    this.rows= [];
                    if (this.artists && this.artists.length > 0){            
                        this.artists.forEach(artist => {
                            let artistObj = {
                                id: artist.id,
                                name: artist.name, 
                                rating: artist.rating,
                                icon: artist.image ? artist.image.url : require('@/assets/images/placeholder.svg'),
                                tier: artist.tier.name
                            };
                            this.rows.push(artistObj)
                        });
                    }
                    this.loaded = true;
                })
        }
    }
}

</script>